<template>
	<el-dialog v-dialogDrag title="新增固定积分类型名称" :visible.sync="visible" width="40%" :before-close="close">
		<el-form label-width="120px" ref="form" :model="formParam" :rules="rules">
			<el-form-item label="所属类型" prop="typeId">
				<el-select v-model="formParam.typeId" placeholder="请选择">
					<el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="类型名称" prop="name">
				<el-input v-model="formParam.name" maxlength="200"></el-input>
			</el-form-item>
			<el-form-item label="固定积分得分" prop="score">
				<el-input-number :min="1"  :precision="0"  v-model="formParam.score"></el-input-number>
			</el-form-item>
			<el-form-item label="备注说明" prop="remark">
				<el-input v-model="formParam.remark" type="textarea" :rows="5"></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button size="small" @click="close">取 消</el-button>
			<el-button size="small" type="primary" @click="submit" :loading="btnLoading">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
	export default {
		data() {
			return {
				formParam: {},
				btnLoading:false,
				rules:{
					typeId: [{
						required: true,
						message: '请选择类型',
						trigger: 'blur'
					}],
					name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}],
					score:[{
						required: true,
						message: '请输入固定积分',
						trigger: 'blur'
					}],
				}
			}
		},
		props: {
			show: {
				type: Boolean,
				default: false,
			},
			list:{
				type: Array,
				default:()=>{
					return []
				},
			},
			id: {
				type: Number | String,
				default: null
			}
		},
		computed: {
			visible: {
				get() {
					if(this.id){
						this.getDetail()
					}else{
						this.formParam={
							typeId:this.$parent.pageParam.typeId
						}
					}
					return this.show;
				},
				set(val) {
					this.$emit("update:show", val);
				},
			},
		},
		watch: {},
		created() {
			console.log(this.show, this.visible);
		},
		mounted() {},
		methods: {
			// 关闭
			close() {
				this.$refs.form.resetFields();
				this.$emit('update:show', false)
			},
			// 获取详情
			async getDetail(){
				let res=await this.$get(`/platform-config/fixedintegral/${this.id}`);
				if(res&&res.code==0){
					this.formParam=res.data
				}
			},
			// 确定提交
			submit() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.btnLoading = true
						if (this.id) {
							this.update()
						} else {
							this.save()
						}
					}
				});
			},
			// 新增
			async save() {
				let res = await this.$post('/platform-config/fixedintegral', this.formParam);
				this.btnLoading = false
				if (res && res.code == 0) {
					this.$message.success("新增成功！");
					this.close()
					this.$parent.getList()
				}
			},
			// 编辑
			async update() {
				let res = await this.$put('/platform-config/fixedintegral', this.formParam);
				this.btnLoading = false
				if (res && res.code == 0) {
					this.$message.success("修改成功！");
					this.close()
					this.$parent.getList()
				}
			},
		},
	};
</script>

<style scoped>
	.editor {
		width: 100%;
		height: 350px;
	}
</style>
